import request from '@/utils/request'

const login = {
    // 获取验证码
    getCode(data) {
        return request({
            url: 'passport/phone_code',
            method: 'post',
            data
        })
    },
    // 验证码登录
    phone_login(data) {
        return request({
            url: 'passport/phone_login',
            method: 'post',
            data
        })
    },
    // 扫码登录
    weichat_code_login(data) {
        return request({
            url: 'passport/weichat_code_login',
            method: 'post',
            data
        })
    },
    passwd_login(data) {
        return request({
            url: 'passport/passwd_login',
            method: 'post',
            data
        })
    },
}

export default login
