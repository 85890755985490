import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import router from "@/router";
import Vue from 'vue'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 500000, // request timeout
    headers: {
        "Content-Type": 'application/x-www-form-urlencoded'
    }
})
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        // config.headers['joyho-token'] = getToken()
        // }
        config.headers['Authorization'] = 'Bearer ' + store.state.token
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        let res
        if (typeof (response.data) === 'string') {
            res = JSON.parse(response.data)
        } else {
            res = response.data
        }
        if (res.token) {
            // setToken(res.token)
        }
        if (res.code === 200) {
            return res
        } else if (res.code !== 200) {
            if (res.code === 401 || res.code == 400800) {
                Vue.prototype.$allEvent.$emit('showLogin')
                store.commit('setLogin', false)
                store.commit('setBind', false)
            } else if (res.code == 400802) {
                MessageBox.confirm('请完善个人资料!', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    router.push({name: 'userIndex', query: {index: 2}})
                }).catch(() => {
                });
            } else if(res.code == -403000){
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 3 * 1000
                })
                return res
            }else {

                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 3 * 1000
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        }
    },
    error => {
        if (error.response.data.code == 400802) {
            MessageBox.confirm('请完善个人资料!', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                router.push({name: 'userIndex', query: {index: 2}})
            }).catch(() => {
            });
            return
        }
        if (error.response.data.code != 401 ) {
            // console.log(error.response.data);
            if(location.href.indexOf('video')>=0){
                MessageBox.alert(error.response.data.msg, '提示', {
                    confirmButtonText: '返回',
                    type:'warning'
                }).then(() => {
                    router.back()
                }).catch(() => {
                });
            }else {
                let name =  window.location.pathname
                if(name != '/class/detail'){
                    Message({
                        message: error.response.data.msg,
                        type: 'error',
                        duration: 3 * 1000
                    })
                }

            }

        } else {
            Vue.prototype.$allEvent.$emit('showLogin')
            store.commit('setLogin', false)
            store.commit('setBind', false)
        }

        return Promise.reject(error)
    }
)

export default service
