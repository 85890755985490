import request from '@/utils/request'

const cate = {
    // 分类
    category(data) {
        return request({
            url: 'category',
            method: 'get',
            params:data
        })
    },
    // 分类列表
    search(data) {
        return request({
            url: 'search',
            method: 'get',
            params:data
        })
    },
}

export default cate
