import request from '@/utils/request'

const cart = {
    // 添加购物车
    cartAdd(data) {
        return request({
            url: 'cart/add',
            method: 'post',
            data
        })
    },
    // 购物车列表
    cartList(data) {
        return request({
            url: 'cart/',
            method: 'get',
            params:data
        })
    },
    // 删除购物车
    cartDelete(data) {
        return request({
            url: 'cart/delete',
            method: 'post',
            data
        })
    },
    // 订单创建
    orderCreate(data) {
        return request({
            url: 'buy/create',
            method: 'post',
            data
        })
    },
    // 订单结算页
    orderConfirm(data) {
        return request({
            url: 'buy/confirm',
            method: 'post',
            data
        })
    },
    // 订单支付
    orderPrepay(data) {
        return request({
            url: 'order/prepay',
            method: 'post',
            data
        })
    },
    check_pay(data) {
        return request({
            url: 'order/check_pay',
            method: 'get',
            params:data
        })
    },
    //
    pre_check(data) {
        return request({
            url: 'buy/pre_check',
            method: 'post',
            data
        })
    },
}

export default cart
