import request from '@/utils/request'

const home = {
    // 首页
    getIndex(data) {
        return request({
            url: 'main',
            method: 'get',
            params:data
        })
    },
    //分类下的推荐
    cate_recommend(data) {
        return request({
            url: 'cate_recommend',
            method: 'get',
            params:data
        })
    },
}

export default home
