<template>
 <div class="">
   <headerBar v-if="$route.meta.headerType == 1"></headerBar>
   <div class="main-content" :class="{'header-two':$route.meta.headerType == 2}">
     <keep-alive>
       <router-view v-if="$route.meta.keepAlive"></router-view>
     </keep-alive>
     <router-view v-if="!$route.meta.keepAlive"></router-view>
   </div>
   <footers v-if="$route.meta.footersType != 2"></footers>
 </div>
</template>

<script>
import headerBar from "@/components/header/headerBar";
import footers from "@/components/header/footers";
export default {
  name: "layout",
  components:{
    headerBar,
    footers
  }
}
</script>

<style scoped>
.main-content{
  min-height: calc(100vh - 265px);
}
.header-two{
  min-height: calc(100vh - 306px) !important;
}
</style>
