<template>
  <div class="header-box flex flex-ac " :class="isFixed?'header-fixed':''">
    <div class=" ct flex flex-ac rela w-max" >
      <template>
        <img class="header-logo cur" @click="goIndex" src="@/assets/img/logo1.png">
        <img src="@/assets/img/header-img.png" class="header-tips">
        <div class="header-menu flex flex-ac ft15">
          <div class="header-menu-one " @click="goIndex">
            首页
          </div>
<!--          <div class="header-menu-one "  @click="goClass('free')">-->
<!--            免费课程-->
<!--          </div>-->
<!--          <div class="header-menu-one "  @click="goClass('item')">-->
<!--            实战课程-->
<!--          </div>-->
          <div class="header-menu-one " v-for="(n,index) in cateList" :key="index" @click="goClass(n)">
            {{ n.name }}
          </div>
        </div>
      </template>
<!--      <template v-if="isClassVideo">-->
<!--          <i class="el-icon-back ft30" @click="back"></i>-->
<!--          <div class="class-title ml20 ft20 bold">开发环境搭建</div>-->
<!--      </template>-->
      <div class="flex flex-pe">
        <div class="header-search flex flex-ac flex-pc  rela">
          <el-input class="header-input" v-model="search" @change="searchInput" placeholder="请输入关键字"></el-input>
          <i class="el-icon-search" @click="searchInput"></i>
        </div>
        <div class="header-right flex flex-ac">
<!--          <i class="el-icon-shopping-cart-2" v-if="userInfo.id" @click="goCart"></i>-->
          <div class="header-login flex flex-ac ft14" v-if="!userInfo.id" >
            <div class="login-font" @click="showReg(2)">登录</div>
            <div style="color: #d9dde1">/</div>
            <div class="login-font" @click="showReg(3)">注册</div>
          </div>
          <div class="header-login flex flex-ac ft14" v-else >
            <div class="login-font" @click="goUser(1)">我的课程</div>
            <div class="login-font" @click="logout()">退出</div>
          </div>
        </div>
      </div>
      <div class="dialog-box" v-if="dialogVisible">
        <div class="login-box">

          <div class="login-top flex-ac flex flex-pj">
            <div class="flex flex-ac" v-if="loginType != 5 && loginType != 6 ">
              <div @click="changeWx" style="font-weight: normal" :class="{'login-active':loginType == 4}" class="mr15 cur">微信登录</div>
              <div @click="loginType=1" style="font-weight: normal" :class="{'login-active':loginType == 1}" class="mr15 cur">账号登录</div>
              <div @click="loginType=2" style="font-weight: normal" :class="{'login-active':loginType == 2}" class="cur">验证码登录</div>
            </div>
            <div class="flex flex-ac" v-if="loginType == 5 || loginType == 6">
              <div @click="changeReg" style="font-weight: normal" :class="{'login-active':loginType == 6}" class="mr15 cur">微信注册</div>
              <div @click="loginType=5" style="font-weight: normal" :class="{'login-active':loginType == 5}" class="mr15 cur">手机号注册</div>
            </div>
            <div>
              <i class="el-icon-close cur" @click="dialogVisible = false"></i>
            </div>
          </div>
          <div v-show="loginType == 4 || loginType == 6" class="flex flex-ac flex-pc mt20">
            <div id="wxCode" style="margin: 0 auto;height: 370px;"></div>
          </div>
          <div class="login-input" v-if="loginType != 4 && loginType != 6 ">
            <el-form ref="form" class="form-box" :model="form">
              <el-form-item prop="nick_name" v-if="loginType==2 ||loginType==1 || loginType==5">
                <div class="flex flex-ac flex-pc">
                  <el-input placeholder="请输入手机号" v-model="form.account_number" @mouseout.native.stop=""
                            class="input-box"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="nick_name"  v-if="loginType == 1">
                <div class="flex flex-ac flex-pc rela">
                  <el-input type="" placeholder="请输入密码" show-password v-model="form.pass" @mouseout.native.stop=""
                            class="input-box"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="code" v-if="loginType==2 || loginType==5">
                <div class="flex flex-ac flex-pc" style="align-items: center">
                  <el-input placeholder="请输入验证码" v-model="form.code" style="width: 180px !important;"></el-input>
                  <div class="code-box"  v-if="!msg" @click="getCode">获取验证码</div>
                  <div class="code-box" v-else>{{ msg }}</div>
                </div>
              </el-form-item>
<!--              <el-form-item>-->
<!--                <div class="flex flex-ac flex-pj ct" style="width: 320px">-->
<!--                  <div class="flex flex-ac">-->
<!--                    <el-checkbox v-model="checked">七天内自动登录</el-checkbox>-->
<!--                  </div>-->
<!--                  <div class="c999 ft14 flex flex-ac flex-pc">-->
<!--                    <div class="tip-font cur">忘记密码</div>-->
<!--                    <div class="ml10 mr10">|</div>-->
<!--                    <div class="tip-font cur">遇到问题</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-form-item>-->
              <el-form-item>
                <div class="flex flex-ac flex-pc" v-if="loginType == 1">
                  <el-button type="primary" style="width: 300px;height: 50px;border-radius: 6px;background: #FF2840;border: 0" @click="submitPass">登录
                  </el-button>
                </div>
                <div class="flex flex-ac flex-pc" v-if="loginType == 2">
                  <el-button type="primary" style="width: 300px;height: 50px;border-radius: 6px;background: #FF2840;border: 0" @click="submitCode">登录
                  </el-button>
                </div>
                <div class="flex flex-ac flex-pc" v-if="loginType == 5">
                  <el-button type="primary" style="width: 300px;height: 50px;border-radius: 6px;background: #FF2840;border: 0" @click="submitCode">注册
                  </el-button>
                </div>
                <div class="main-color ft14 mt10 cur" v-if="loginType != 5 && loginType != 6" @click="changeReg" style="text-align: center">快速注册</div>
                <div class="main-color ft14 mt10 cur" v-if="loginType == 5" @click="changeWx" style="text-align: center">快速登录</div>
<!--                <div class="wechat-img cur" @click="changeType(4)">-->
<!--                  <img src="@/assets/img/wechat.png">-->
<!--                </div>-->
<!--                <div class="ft14 c999" style="text-align: center">-->
<!--                  登录即同意 <span class="main-color cur">《隐私政策》</span>-->
<!--                </div>-->
              </el-form-item>

            </el-form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerBar",
  data() {
    return {
      dialogVisible: false,
      loginType: 4,//1账号 2验证码
      form: {
        account_number:'',
        code:'',
        pass:''
      },
      checked:false,
      msg:'',
      userInfo:{},
      search:'',
      isFixed:false,
      isFirst:true,
      nowClass:'',
      isClassVideo:'',
      cateList:[]
    }
  },
  created() {
    if(this.$route.path == '/index'){
      this.isFixed = true
    }else {
      this.isFixed = false
    }
    // if(!this.$store.state.isLogin && this.$route.path != '/index'){
    //   this.loginType = 2
    //   this.dialogVisible = true
    // }
    if(this.$store.state.isLogin){
      this.getUserInfo();
    }
    this.$allEvent.$on('closeDia',()=>{
      this.dialogVisible = false
      this.loginType = 2
    })
    this.$allEvent.$on('refreshIndex',()=>{
      this.getUserInfo();
    })
    this.$allEvent.$on('showLogin',()=>{
      if(this.$route.name == 'index')return
      this.changeWx()
      this.dialogVisible = true
    })
    this.$allEvent.$on('cleanClass',()=>{
      this.nowClass = ''
    })
    this.getCate();
    // if(this.$route.path == '/class/video'){
    //   this.isClassVideo = false
    // }else{
    //   this.isClassVideo  = false
    // }
  },
  computed: {
    // ...mapState(['isLogin'])
  },
  watch: {
    '$store.state.isLogin': {
      handler() {
        if(!this.$store.state.isLogin&& this.$route.path != '/index'){
          // this.loginType = 1
          // this.dialogVisible = true
        }
      },
      deep: true,
      immediate: true
    },
    $route:{
      handler(){
        if(this.$route.path == '/index'){
            this.isFixed = true
        }else {
          this.isFixed = false
        }
        if(this.$route.path == '/class/video'){
          this.isClassVideo = true
        }else{
          this.isClassVideo  = false
        }
      }
    }
  },
  methods:{
    changeWx(){
      this.changeType(4)
    },
    changeReg(){
      this.loginType = 6
      this.initWx()
    },
    getCate(){
      this.$api.cate.category().then(res=>{
        this.cateList = res.data.nav
      })
    },
    cleanForm(){
      this.form = {
        account_number:'',
        code:'',
        pass:''
      }
    },
    back(){
      this.$router.back()
    },
    logout(){
      if(this.$route.path != '/index'){
        this.$router.push('/')
      }
      this.$store.commit('setToken','')
      this.$store.commit('setUserInfo','')
      this.$store.commit('setLogin',false)
      this.$store.commit('setBind',false)
      this.userInfo= {}
    },
    changeType(){
      this.loginType = 4
      this.initWx()
    },
    submitPass(){
      if(!this.form.account_number){
        this.$message.error('请输入电话！')
        return
      }
      if(!this.form.pass){
        this.$message.error('请输入密码！')
        return
      }
      this.$api.login.passwd_login({
        phone:this.form.account_number,
        passwd:this.form.pass
      }).then(res=>{
        if(res.code == 200){
          this.$store.commit('setToken',res.data.token)
          this.$store.commit('setUserInfo',res.data.data)
          this.$store.commit('setLogin',true)
          this.getUserInfo();
          this.dialogVisible = false
          this.cleanForm();
          location.reload()
        }
      })
    },
    showReg(r){
      this.dialogVisible = true
      if(r == 2){
        setTimeout(()=>{
          this.changeWx()
        },100)
        return
      }
     if(r == 3){
       this.loginType =6
       setTimeout(()=>{
         this.changeReg()
       },100)
     }
    },
    goUser(type){
      this.$allEvent.$emit('class')
      this.$router.push({name:'userIndex',query:{index:type}})
    },
    goCart(){
      if(this.$route.path == '/cart/list'){
        this.$allEvent.$emit('showCart')
        return
      }
      this.$router.push({name:'cartList'})
    },
    searchInput(){
      if(this.$route.path == "/class/list"){
        this.$allEvent.$emit('reClassList',this.search)
      }else {
        this.$router.push({name:'classList',query:{name:this.search}})
      }
    },
    // 微信扫码登录
    initWx(){
      let obj = new WxLogin({
        self_redirect: false,
        id: "wxCode",
        appid: "wxeaaffe4f5bae134e",
        scope: "snsapi_login",
        redirect_uri: 'https%3A%2F%2Fwww.icloudedu.com%2Freg%2Fauth',
        state: "232323",
        style: "",
        href: "https://www.icloudedu.com/wxLogin.css"
      });
    },
    // 获取用户信息
    getUserInfo(){
      if(this.$store.state.token){
        this.$api.user.memberInfo().then(res=>{
          if(res.code == 200){
            this.userInfo = res.data.info.data
          }
        })
      }
    },
    goClass(row){

      if(this.nowClass == row.id)return
      this.nowClass = row.id
      this.$router.replace({name:'classList',query:{sub_cid:row.id,className:row.name}})
      // if(this.$route.path == '/class/list'){
      //   this.$allEvent.$emit('changeClassType',type)
      // }else {
      //   this.$router.push({name:'classList',query:{type:type}})
      // }
    },
    goIndex(){
      if(this.$route.name == 'index')return
      this.nowClass = ''
      this.$router.push({name:'index'})
    },
    getCode(){
      if(!this.form.account_number){
        this.$message.error('请输入电话！')
        return
      }
      this.$api.login.getCode({
        phone:this.form.account_number
      }).then(res=>{
        if(res.code == 200){
          let time = 60
          let timer = setInterval(() => {
            if (time <= 0) {
              clearInterval(timer)
              this.msg = ''
            } else {
              time--
              this.msg = time + 's'
            }
          }, 1000)
        }
      })
    },
    //  验证码登录
    submitCode(){
      if(!this.form.account_number){
        this.$message.error('请输入电话！')
        return
      }
      if(!this.form.code){
        this.$message.error('请输入验证码！')
        return
      }
      this.$api.login.phone_login({
        phone:this.form.account_number,
        code:this.form.code
      }).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.$store.commit('setToken',res.data.token)
          this.$store.commit('setUserInfo',res.data.data)
          this.$store.commit('setLogin',true)
          this.getUserInfo();
          this.dialogVisible = false
          if(res.data.need_extend ==1){
              this.$message.success('登录成功，请注册完善信息~')
              this.$router.push({name:'reg'})
          }else {
            location.reload()
          }
          this.cleanForm();
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@media screen and (max-width: 1500px){
  .w-max{
    width: 1200px !important;
  }
  .header-tips{
    margin: 0 10px 0 40px !important;
  }
}
.class-w{
  width: 90vw;
  height: 75px;
}
.class-title{
  width: 50%;
}
.w-max {
  width: 1336px;
}
.wechat-img{
  text-align: center;
  img{
    width: 50px;
    height: 50px;
  }
}
.pass-img{
  width: 30px;
  height: 30px;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.tip-font{
    color: #999999;
  &:hover{
    color: #545c63;
  }
}
.header-fixed{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.header-box {
  width: 100%;
  height: 75px;
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(0,0,0,.1);

  .header-logo {
    //width: 126px;
    height: 60px;
    //margin-left: 4px;
    margin: 0 20px;
  }
  .header-tips{
    height: 60px;
    margin: 0 100px 0 40px;
  }

  .header-menu {
    .header-menu-one {
      padding: 0 16px;
      color: #545c63;
      cursor: pointer;

      &:hover {
        color: #1C1F21;
      }
    }
  }

  .header-search {
    height: 75px;
    min-width: 32px;
    //margin-left: 324px;
    /deep/.el-input__inner{
      height: 40px;
    }
    margin-left: 40px;
    //position: absolute;
    //right: 170px;
    //top: 50%;
    //transform: translateY(-50%);
    .el-icon-search {
      position: absolute;
      right: 10px;
      top: 30px;
      font-size: 20px;
    }

    .header-word {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 140px;
      color: #9199a1;
      cursor: pointer;

      a {
        color: #9199a1;
        padding: 0 8px;

        &:hover {
          color: #019ffd;
        }
      }
    }

    .header-input {
      border: none;
      width: 220px;
      height: 40px;
      background: #f3f5f6;
      border-radius: 8px;

      /deep/ .el-input__inner {
        border: none !important;
        background: #f3f5f6;
      }
    }
  }

  .header-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .el-icon-shopping-cart-2 {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #019ffd;
      }
    }

    .header-login {
      //margin-left: 40px;

      .login-font {
        padding: 0 12px;
        color: #4d555d;
        cursor: pointer;

        &:hover {
          color: #019ffd;
        }
      }
    }
  }
}

.dialog-box {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  .login-box {
    width: 384px;
    //height: 488px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 25px;
    padding-bottom: 15px;

    .login-top {
      padding: 0 30px;
      font-size: 18px;
      font-weight: bold;
      color: #787d82;

      .el-icon-close {
        font-size: 26px;
      }

      .login-active {
        color: #f20d0d;
        position: relative;
        font-weight: bold !important;
        &::before {
          position: absolute;
          bottom: -6px;
          left: 50%;
          transform: translateX(-50%);
          content: " ";
          width: 16px;
          height: 4px;
          line-height: 4px;
          background: #f20d0d;
          border-radius: 2px;
        }
      }
    }
  }
}

.form-box {
  margin:30px auto 0 auto ;
  .code-box {
    width: 128px;
    height: 50px;
    background: #FFF4F5;
    border: 1px solid #FF2840;
    border-radius: 6px;
    text-align: center;
    line-height: 50px;
    color: #FF2840;
    font-size: 13px;
    margin-left: 11px;
    cursor: pointer;
    user-select: none;
  }
  /deep/.el-input .el-input__clear{
    font-size: 20px;
  }
}

.login-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

/deep/ .el-input__inner {
  background: rgba(28, 31, 33, .06);
  height: 50px;
}

.input-box {
  width: 320px;

}

/deep/ .el-form-item__label {
  line-height: 50px;

}
</style>
