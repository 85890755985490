<template>
  <div class="w100">
    <div class="footer-box c999 flex flex-ac flex-pj w-max">
      <div class="flex flex-ac">
        <img class="footer-img" src="@/assets/img/footer-logo.png" alt="">
        <div>
          <div class="mb5"> 网站持有者:北京正道智源教育科技有限公司</div>
          <div class="mb5"> 学术支持者:北京全道智源教育科技院</div>
          <div class="mb5">电话: <a href="tel:400-1046668">400-1046668</a></div>
          <div class="mb5">邮箱: <a class="c999" href="mailto:icloudcum@vip.126.com">icloudcum@vip.126.com</a></div>
          <div class="mb5">版权所有 Since 2022;AIl Rights Reserved</div>
          <div class="mb5">京ICP备00000号-1 京公网安备1x0000000x号</div>
        </div>
      </div>
      <div>
        <div class="mb5">学习QQ群:1139558793</div>
        <div class="mb5">学习微信:工作过程系统化课程(17610613176)</div>
        <div  class="mb5">小程序:天工云课堂</div>
        <div class="mb5">百度百家号:工作过程系统化课程</div>
        <div class="mb5">微信公众号:工作过程系统化课程、智能云课程</div>
        <div class="mb5">微信视频号:工作过程系统化课程</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footers"
}
</script>

<style scoped lang="less">
.w100{
  border-top: 1px solid #e5e4e4;
}
  .footer-box{
    //text-align: center;
    //line-height: 130px;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    //width: 100vw;
    //z-index: -1;

    a{
      color: #999999 !important;
    }
    margin: 0 auto;
    padding: 20px 0;
    .footer-img{
      width: 100px;
      height: 100px;
      margin-right: 30px;
    }
  }
</style>
