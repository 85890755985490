import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
const state = {
    isLogin: false,//用户是否登陆
    token:'',
    userInfo:{},
    isBind: false
}

const mutations = {//只能通过(commit)mutations里边的方法来修改state里边的数据(同步方法)
    setLogin(state, data) {//修改登陆状态方法
        state.isLogin = data
    },
    setToken(state, data){
        state.token =data
    },
    setUserInfo(state,data){
        state.userInfo = data
    },
    setBind(state,data){
        state.isBind = data
    }
}

const getters = {//store的计算属性

}

const actions = {//actions只能提交(dispatch)mutations里边的方法来修改state的数据(异步方法)

}
const store = new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    plugins:[createPersistedState()]
})
export default store
