import request from '@/utils/request'

const classes = {
    // 获取分类
    getCate(data) {
        return request({
            url: 'category',
            method: 'get',
            params:data
        })
    },
    //课程详情
    detail(data) {
        return request({
            url: 'detail',
            method: 'get',
            params:data
        })
    },
    //章节详情
    chapter(data) {
        return request({
            url: 'chapter',
            method: 'get',
            params:data
        })
    },
    // 获取播放信息
    playInfo(data) {
        return request({
            url: 'play/',
            method: 'get',
            params:data
        })
    },
}

export default classes
