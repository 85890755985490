import request from '@/utils/request'

const user = {
    // 编辑个人资料
    editMemberInfo(data) {
        return request({
            url: 'member/info',
            method: 'post',
            data
        })
    },
    // 获取资料
    memberInfo(data) {
        return request({
            url: 'member/info',
            method: 'get',
            params: data
        })
    },
    // 我的课程
    memberBooks(data) {
        return request({
            url: 'member/books',
            method: 'get',
            params: data
        })
    },
    phone_code(data) {
        return request({
            url: 'member/phone_code',
            method: 'get',
            params: data
        })
    },
    change_phone(data) {
        return request({
            url: 'member/change_phone',
            method: 'post',
            data
        })
    },
    bind_phone(data) {
        return request({
            url: 'member/bind_phone',
            method: 'post',
            data
        })
    },
    set_passwd(data) {
        return request({
            url: 'member/set_passwd',
            method: 'post',
            data
        })
    },
    change_passwd(data) {
        return request({
            url: 'member/change_passwd',
            method: 'post',
            data
        })
    },
    invoice_create(data) {
        return request({
            url: 'invoice/create',
            method: 'post',
            data
        })
    },
    invoice_index(data) {
        return request({
            url: 'invoice/index',
            method: 'get',
            params: data
        })
    },
    invoice_edit(data) {
        return request({
            url: 'invoice/edit',
            method: 'post',
            data
        })
    },
    invoice_del(data) {
        return request({
            url: 'invoice/delete',
            method: 'get',
            params: data
        })
    },
    invoice_list(data) {
        return request({
            url: 'order/invoice_list',
            method: 'get',
            params: data
        })
    },
    apply_invoice(data) {
        return request({
            url: 'order/apply_invoice',
            method: 'post',
            data
        })
    },
    bind_weichat(data) {
        return request({
            url: 'member/bind_weichat',
            method: 'post',
            data
        })
    },
    unbind_weichat(data) {
        return request({
            url: 'member/unbind_weichat',
            method: 'post',
            data
        })
    },
}

export default user
