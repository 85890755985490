import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from "../components/layout";
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: layout,
            children: [{
                path: '/',
                name: 'index',
                component: () => import('@/pages/index/index'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            }]
        },
       {
            path: '/login',
            component: () => import('@/pages/login/login.vue')
        },
        {
            path: '/404',
            component: () => import('@/pages/common/404')
        },
        {
            path: '/stateError',
            component: layout,
            children: [{
                path: 'stateError',
                name: 'stateError',
                component: () => import('@/pages/common/stateError'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            }]
        },
        {
            path: '/class',
            component: layout,
            children: [{
                path: 'list',
                name: 'classList',
                component: () => import('@/pages/class/classList'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            },{
                path: 'detail',
                name: 'classDetail',
                component: () => import('@/pages/class/classDetail'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            },{
                path: 'video',
                name: 'classVideo',
                component: () => import('@/pages/class/classVideo'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1,
                    footersType:2
                },
            }]
        },
        {
            path: '/cart',
            component: layout,
            children: [{
                path: 'list',
                name: 'cartList',
                component: () => import('@/pages/cart/cartList'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            },{
                path: 'confirm',
                name: 'confirmOrder',
                component: () => import('@/pages/cart/confirmOrder'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            },{
                path: 'pay',
                name: 'payOrder',
                component: () => import('@/pages/cart/payOrder'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            }]
        },
        {
            path: '/reg',
            component: layout,
            children: [{
                path: 'reg',
                name: 'reg',
                component: () => import('@/pages/login/reg'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            },{
                path: 'auth',
                name: 'auth',
                component: () => import('@/pages/login/auth'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            }]
        },
        {
            path: '/user',
            component: layout,
            children: [{
                path: 'index',
                name: 'userIndex',
                component: () => import('@/pages/user/userIndex'),
                meta: {
                    title: '天工云课堂 - 学会教人做事，成为教学名师',
                    headerType:1
                },
            }]
        }
    ]
})
router.beforeEach((to, from, next) => {
    /*
     设置页面title标题..
    */
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.meta.background) {
        document.body.style.backgroundColor = to.meta.background;
    } else {
        document.body.style.backgroundColor = "#f3f4f5";
    }

    if (to.meta.class) {
        document.body.className = to.meta.class;
    }
    /*
      设置页面class
     */
    /*
    检测页面是否需要登录，如果需要且没有登录，自动跳转到登录页面
    */

    if (to.matched.length === 0) {
        next('/404')
    } else {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            next();
        } else {
            next();
        }
    }





})
export default router
