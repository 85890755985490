import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './api/index'
import store from "@/store";
Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.prototype.$allEvent = new Vue();
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI);
Vue.mixin({
  methods:{
    //添加购物车
    addCart(e){
      if(!store.state.isLogin){
        this.$allEvent.$emit('showLogin')
        return
      }
      this.$api.cart.cartAdd({
        iid:e
      }).then(res=>{
        if(res.code == 200){
          this.$message.success('添加成功！')
          this.$allEvent.$emit('addCartSucc')
        }
      })
    }
  }
})
//公共样式
import './assets/css/public.css'
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
