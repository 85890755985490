import home from './home'
import login from './login'
import user from "@/api/user";
import classes from "@/api/classes";
import cate from "@/api/cate";
import cart from "@/api/cart";

const api = {
    home,
    login,
    user,
    classes,
    cate,
    cart
}

export default api
